import { getElement } from "../element/element";
import './burger.css';

export function getBurger() {
    const burger = getElement('button', 'burger');

    const line1 = getElement('span');
    const line2 = getElement('span');

    

    burger.addEventListener('click', () => {
        burger.classList.toggle('active');
        let targetSidebat = document.querySelector('.sidebar');
        targetSidebat.classList.toggle('active');
    });

    burger.append(line1, line2);
    return burger;
}