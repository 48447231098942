import { getElement } from "../element/element";
import './footer.css';

export function getFooter() {
    const footer = getElement('footer', 'footer');
    const container = getElement('div', 'container', 'footer__container');

    const tgLink = getElement('a', 'footer__link');
    tgLink.href = 'https://t.me/pogremuhavh';

    tgLink.innerText = 'Аккаунт в телеграм';

    const phoneLink = getElement('a', 'footer__link');
    phoneLink.href = 'tel:79056585002';
    phoneLink.innerText = '7 (905) 658 50 02';

    container.append(tgLink, phoneLink);

    

    footer.append(container);
    return footer;
}