import { getElement } from "../element/element";
import './modal.css';

export function getModal(innerContent) {
    const modalContainer = getElement('div', 'modal-container');
    const modal = getElement('div', 'modal');

    const modalClose = getElement('button', 'btn-reset', 'modal__close');
    modalClose.innerText = 'х';

    modalContainer.addEventListener('click', () => {
        modalContainer.classList.remove('open');
    });

    modal.addEventListener('click', (e) => {
        e.stopPropagation();
    });

    modalClose.addEventListener('click', (e) => {
        modalContainer.classList.remove('open');
    });

    function openModal() {
        modalContainer.classList.add('open');
    }

    modal.append(innerContent, modalClose);
    modalContainer.append(modal);

    return { modalContainer, openModal };
}