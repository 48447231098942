import { router } from "../../main";
import { getElement } from "../element/element";
import './navLink.css';

export function getNavLink(path='', text='') {
    const link = getElement('a', 'nav__link');
    link.href = path;
    link.textContent = text;
    link.addEventListener("click", function (event) {
        event.preventDefault()
        router.navigate(path);

        
        let targetSidebat = document.querySelector('.sidebar');

        if (targetSidebat.classList.contains('active')) {
            let targetBurger = document.querySelector('.burger');
            targetSidebat.classList.remove('active');
            targetBurger.classList.remove('active');
        }
    });
    return link;
}