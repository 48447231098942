import { getElement } from "../element/element";
import { getNavLink } from "../navLink/navLink";
import './sidebar.css';

export function getSidebar() {
    const sidebar = getElement('div', 'sidebar');

    const navContainer = getElement('nav', 'sidebar__nav', 'nav');
    const navList = getElement('ul', 'sidebar__list', 'list-reset');

    const links = {
        "home": getNavLink("/", "Главная"),
        "catalog": getNavLink("/catalog", "Каталог"),
    }
    
    for (const oneLink in links) {
        const navLi = getElement('li', 'sidebar__item');
        navLi.append(links[oneLink]);
        navList.append(navLi);
    }
    
    const setActiveLink = function (link = "") {
        for (const oneLink in links) {
            links[oneLink].classList.remove("active");
        }
        if (link !== "") {
          links[link].classList.add("active");
        }
    };

    navContainer.append(navList);
    sidebar.append(navContainer);

    return { sidebar, setActiveLink }
}