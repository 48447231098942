import Navigo from "navigo";
import { getHeader } from "./components/header/header";
import { getMainContainer } from "./components/mainContainer/mainContainer";
import { getElement } from "./components/element/element";
import { getSidebar } from "./components/sidebar/sidebar";
import { getFooter } from "./components/footer/footer";
import { doc } from "firebase/firestore";

const wrapper = document.getElementById("wrapper");
export const overlay = getElement("div", "overlay");
export let orderList = getOrderListFromLocalStorage();

export const router = new Navigo("/");

const header = getHeader();
export const sidebar = getSidebar();
const mainContainer = getMainContainer();
const footer = getFooter();

function getOrderListFromLocalStorage() {
  const storedOrderList = localStorage.getItem("orderList");
  return storedOrderList ? JSON.parse(storedOrderList) : [];
}

export function addProductInBasket(newProduct) {
  const existingProduct = orderList.find(
    (product) =>
      product.id === newProduct.id &&
      product.targetType === newProduct.targetType
  );

  if (existingProduct) {
    existingProduct.quantity += 1;
  } else {
    const newIndex =
      orderList.length > 0 ? orderList[orderList.length - 1].index + 1 : 0;
    newProduct.index = newIndex;
    newProduct.quantity = 1;
    orderList.push(newProduct);
  }
  localStorage.setItem("orderList", JSON.stringify(orderList));
  renderOrderInBusket(orderList);
}

export function removeProductFromBusket(index) {
  console.log(index);
  orderList = orderList.filter((product) => product.index !== index);
  console.log(orderList);
  localStorage.setItem("orderList", JSON.stringify(orderList));
  renderOrderInBusket(orderList);
}

export function renderOrderInBusket(orderList) {
  const busketBlockList = document.querySelector(".busket__block--list");
  busketBlockList.innerHTML = "";
  let orderSize = 0;
  let orderPrice = 0;

  orderList.forEach((product) => {
    console.log(product);
    const li = getElement("li", "busket__block--item");
    li.dataset.index = product.index;
    const liImage = getElement("img", "busket__item--img");
    liImage.src = product.image;
    const liInfo = getElement("div", "busket__item--info");
    const liTitle = getElement("div", "busket__item--title");
    liTitle.innerText = `${product.title} `;

    if (product.targetType) {
      liTitle.innerText += `(${product.targetType})`;
    }

    const liPrice = getElement("div", "busket__item--price");
    liPrice.innerText = `${product.price} руб`;
    const liQuantity = getElement("div", "busket__item--quantity");
    const liQuantityPlus = getElement(
      "button",
      "busket__item--plus",
      "btn-reset"
    );
    liQuantityPlus.innerText = "+";
    const liQuantityText = getElement("div");
    const liQuantityMinus = getElement(
      "button",
      "busket__item--minus",
      "btn-reset"
    );
    liQuantityMinus.innerText = "-";
    liQuantityText.innerText = `${product.quantity}`;

    const liRemove = getElement("button", "busket__item--remove", "btn-reset");
    liRemove.innerText = "Удалить";

    liQuantityPlus.addEventListener("click", () => {
      product.quantity++;
      renderOrderInBusket(orderList);
      localStorage.setItem("orderList", JSON.stringify(orderList));
    });

    liQuantityMinus.addEventListener("click", () => {
      if (+product.quantity - 1 === 0) {
        removeProductFromBusket(product.index);
      } else {
        product.quantity--;
        localStorage.setItem("orderList", JSON.stringify(orderList));
        renderOrderInBusket(orderList);
      }
    });

    liRemove.addEventListener("click", () =>
      removeProductFromBusket(product.index)
    );
    liQuantity.append(liQuantityMinus, liQuantityText, liQuantityPlus);
    liInfo.append(liTitle, liPrice, liQuantity, liRemove);
    li.append(liImage, liInfo);

    busketBlockList.append(li);
    orderSize += product.quantity;
    orderPrice += product.quantity * product.price;
  });

  const busketBtn = document.querySelector(".busket__btn--span");
  const busketFullPrice = document.querySelector(".busket__block--fullprice");
  busketFullPrice.innerText = `Итого: ${orderPrice} руб`;
  busketBtn.innerText = orderSize;
}

router.on("/", async () => {
  mainContainer.innerHTML = "";
  const moduleMain = await import("/src/js/pages/mainPage/mainPage.js");
  const mainPage = moduleMain.getMainPage();
  mainContainer.append(mainPage);
  header.setActiveLink("home");
  sidebar.setActiveLink("home");
});

router.on("/admin", async () => {
  mainContainer.innerHTML = "";
  const moduleAdmin = await import("/src/js/pages/adminPage/adminPage.js");
  const adminPage = moduleAdmin.getAdminPage();
  mainContainer.append(adminPage);
  header.setActiveLink("");
});

router.on("/catalog/:id", async ({ data }) => {
  mainContainer.innerHTML = "";
  const moduleProduct = await import(
    "/src/js/pages/productPage/productPage.js"
  );
  const productPage = moduleProduct.getProductPage(data);
  await mainContainer.append(productPage);
  header.setActiveLink("");
  sidebar.setActiveLink("");
});

router.on("/catalog", async () => {
  mainContainer.innerHTML = "";
  const moduleCatalog = await import(
    "/src/js/pages/catalogPage/catalogPage.js"
  );
  const catalogPage = moduleCatalog.getCatalogPage().catalogPage;
  mainContainer.append(catalogPage);
  header.setActiveLink("catalog");
  sidebar.setActiveLink("catalog");
});

router.notFound(async () => {
  mainContainer.innerHTML = ""
  const moduleNotFound = await import("/src/js/pages/notFoundPage/notFoundPage.js")
  const notFoundPage = moduleNotFound.getNotFoundPage()
  mainContainer.append(notFoundPage)
  header.setActiveLink();
})

router.resolve();

require("dotenv").config();

export const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
};

wrapper.append(overlay, header.header, mainContainer, sidebar.sidebar, footer);
