import { router } from "../../main";
import { getBusket } from "../busket/busket";
import { getElement } from "../element/element";
import { getNavLink } from "../navLink/navLink";
import logoImage from "/src/assets/img/logo.png";
import './header.css';
import { getBurger } from "../burger/burger";

export function getHeader() {
    const header = getElement('header', 'header');
    const container = getElement('div', 'container', 'header__container');

    const headerLogo = getElement('a', 'header__logo');
    headerLogo.href = '/';

    headerLogo.addEventListener('click', (event) => {
        event.preventDefault();
        router.navigate('/');
    });

    const burger = getBurger();

    const actionsBlock = getElement('div', 'header__actions');

    const headerLogoImg = getElement('img');
    headerLogoImg.src = logoImage;

    const navContainer = getElement('nav', 'header__nav', 'nav');
    const navList = getElement('ul', 'nav__list', 'list-reset');

    const links = {
        "home": getNavLink("/", "Главная"),
        "catalog": getNavLink("/catalog", "Каталог"),
    }
    
    for (const oneLink in links) {
        const navLi = getElement('li', 'nav__item');
        navLi.append(links[oneLink]);
        navList.append(navLi);
    }
    
    const setActiveLink = function (link = "") {
        for (const oneLink in links) {
            links[oneLink].classList.remove("active");
        }
        if (link !== "") {
          links[link].classList.add("active");
        }
    };

    const busket = getBusket();

    navContainer.append(navList);
    headerLogo.append(headerLogoImg);
    actionsBlock.append(busket.busket, burger);
    container.append(headerLogo, navContainer, actionsBlock);
    header.append(container);
    
    return { header, setActiveLink };
}