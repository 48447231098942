import axios from "axios";
import { orderList, renderOrderInBusket } from "../../main";
import { getElement } from "../element/element";

import "./orderForm.css";

export function getOrderForm() {
  const form = getElement("form", "order-form");

  const formSubmit = getElement("button", "btn-reset", "order-form__submit");
  formSubmit.innerText = "Оформить";
  formSubmit.type = "submit";

  const feedback = getElement('select', 'order-form__select');
  feedback.name = 'feedback';
  const optTg = getElement('option');
  optTg.value = 'tg';
  optTg.innerText = 'Телеграм';
  const optPhone = getElement('option');
  optPhone.value = 'phone';
  optPhone.innerText = 'По телефону';
  const optWp = getElement('option');
  optWp.value = 'wp';
  optWp.innerText = 'WhatsApp';
  
  const textArea = getElement('textarea');
  textArea.name = 'text';
  textArea.placeholder = 'Введите дополнительное сообщение';

  const formInput = [
    {
      placeholder: "ФИО",
      required: true,
      className: "name",
      spanText: "Введите фио",
      name: "name",
    },
    {
      placeholder: "Номер телефона",
      required: true,
      className: "phone",
      spanText: "Введите ваш номер телефона",
      name: "phone"
    },
    {
      placeholder: "Верхняя хава",
      required: true,
      className: "city",
      spanText: "Ваш населенный пункт",
      name: "city"
    },
    {
      placeholder: "Ул Пушкина, д20",
      required: true,
      className: "adress",
      spanText: "Введите адрес",
      name: "adress"
    }
  ];

  const formTitle = getElement("h3", "order-form__title");
  formTitle.innerText = "Оформление заказа";

  const formPrice = getElement("div", "order-form__price");

  const formInfo = getElement("div");

  formInfo.append(formTitle, formPrice);

  function getFullPrice() {
    let targetPrice = 0;
    for (let i = 0; i < orderList.length; i++) {
      targetPrice += orderList[i].price * orderList[i].quantity;
    }
    return targetPrice;
  }

  function renderInputs() {
    form.innerHTML = "";
    form.append(formInfo);
    

    formPrice.innerText = `Общая сумма заказа: ${getFullPrice()} руб`;

    formInput.forEach((element) => {
      const label = getElement("label", "order-form__label");
      const span = getElement("span");
      span.innerText = element.spanText;
      const input = getElement(
        "input",
        "order-form__inpit",
        `order-form__${element.className}`
      );
      input.name = element.name;
      input.required = element.required;
      input.placeholder = element.placeholder;

      label.append(span, input);
      form.append(label);
    });

    const feedbackSpan = getElement('span');
    feedbackSpan.innerText = 'Выберете способ обратной связи';

    form.append(feedbackSpan, feedback, textArea);
    form.append(formSubmit);
  }

  const TG_TOKEN = process.env.TG_TOKEN;
  const CHAT_ID = process.env.CHAT_ID;
  const URI_API = `https://api.telegram.org/bot${TG_TOKEN}/sendMessage`;

 

  form.addEventListener("submit", function (e) {
    e.preventDefault();

    let cleanedOrderList = orderList.map(({ title, price, quantity, targetType }) => ({ title, price, quantity, targetType }));

    let message = `<b>Заявка с сайта</b>\n`;
    message += `<b>Отправитель: </b> ${this.name.value}\n`;
    message += `<b>Номер телефона: </b> ${this.phone.value}\n`;
    message += `<b>Населенный пункт: </b> ${this.city.value}\n`;
    message += `<b>Адрес: </b> ${this.adress.value}\n`;
    message += `<b>Обратная связь: </b> ${this.feedback.value}\n`;
    message += `<b>Сообщение: </b> ${this.text.value}\n`;
    
    message += `<b>Сумма заказа: </b> ${getFullPrice()}\n`;

    message += `${JSON.stringify(cleanedOrderList)}`;
    e.disabled = "disabled";
    axios
      .post(URI_API, {
        chat_id: CHAT_ID,
        parse_mode: "html",
        text: message,
      })

      .then((res) => {
        this.name.value = "";
      })
      .catch((err) => {
        console.warn(err);
      })
      .finally(() => {
       
        alert("Заявка отправлена!");
        e.disabled = "";
        const modal = document.querySelector(".modal-container");
        modal.classList.remove("open");
        orderList.length = 0;
        renderOrderInBusket(orderList);
        localStorage.setItem("orderList", JSON.stringify(orderList));
      });
  });

  feedback.append(optTg);
  feedback.append(optPhone);
  feedback.append(optWp);

  renderInputs();

  return { form, renderInputs };
}
