import {
  orderList,
  renderOrderInBusket,
  removeProductFromBusket,
} from "../../main";
import { getElement } from "../element/element";
import { getModal } from "../modal/modal";
import { getOrderForm } from "../orderForm/orderForm";
import "./busket.css";

export function getBusket() {
  const busket = getElement("div", "header__busket", "busket");
  const busketBtn = getElement("a", "busket__btn");
  busketBtn.href = "/busket";

  const orderForm = getOrderForm();
  const orderModal = getModal(orderForm.form);

  const busketBlock = getElement("div", "busket__block");
  const busketBlockHeader = getElement("div", "busket__block--header");
  busketBlockHeader.innerText = "Ваша корзина";
  const busketBlockMain = getElement("div", "busket__block--main");
  const busketBlockList = getElement("ul", "busket__block--list", "list-reset");
  const busketBlockFooter = getElement("div", "busket__block--footer");

  const busketBlockFullPrice = getElement("div", "busket__block--fullprice");
  busketBlockFullPrice.innerText = "Итого: ";

  const orderBtn = getElement("button", "button__order", "btn-reset");
  orderBtn.innerText = "Оформить заказ";

  orderBtn.addEventListener("click", () => {
    if (orderList.length) {
      busketBlock.classList.remove("active");
      orderModal.openModal();
      orderForm.renderInputs();
    }
  });

  const busketClose = getElement("button", "busket__block--close", "btn-reset");

  busketBtn.insertAdjacentHTML(
    "beforeend",
    `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.25 3.125H3.65424C4.13263 3.125 4.37183 3.125 4.56628 3.21174C4.73771 3.28821 4.88415 3.41138 4.98886 3.56717C5.10763 3.74388 5.14862 3.97954 5.23058 4.45085L5.76087 7.5M5.76087 7.5L6.99173 14.5774C7.17091 15.6077 7.2605 16.1228 7.52583 16.4939C7.75945 16.8207 8.08606 17.0695 8.46311 17.208C8.89133 17.3654 9.41176 17.315 10.4526 17.2143L23.9832 15.9048C25.0046 15.806 25.5152 15.7566 25.9012 15.5259C26.2411 15.3227 26.513 15.0232 26.6825 14.6652C26.875 14.2589 26.875 13.7458 26.875 12.7197V11.2317C26.875 10.1412 26.875 9.5959 26.665 9.17455C26.4802 8.80377 26.1848 8.49953 25.8197 8.30385C25.4047 8.08148 24.8597 8.06535 23.7697 8.03308L5.76087 7.5Z" stroke="#262F56" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
    <circle cx="10.625" cy="23.75" r="2.5" stroke="#262F56" stroke-width="1.8"/>
    <circle cx="22.5" cy="23.75" r="2.5" stroke="#262F56" stroke-width="1.8"/></svg>`
  );

  const busketQuantitySpan = getElement("span", "busket__btn--span");
  busketQuantitySpan.innerText = +0;

  busketBtn.addEventListener("click", (event) => {
    event.preventDefault();
    busketBlock.classList.add("active");
  });

  // document.addEventListener("click", (event) => {
  //   if (
  //     !busketBlock.contains(event.target) &&
  //     !busketBtn.contains(event.target)
  //   ) {
  //     busketBlock.classList.remove("active");
  //   }
  // });

  busketClose.insertAdjacentHTML(
    "beforeend",
    `<svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.5459 18.4541C19.7572 18.6654 19.876 18.952 19.876 19.2509C19.876 19.5498 19.7572 19.8365 19.5459 20.0478C19.3346 20.2592 19.0479 20.3779 18.749 20.3779C18.4501 20.3779 18.1635 20.2592 17.9521 20.0478L12 14.0937L6.0459 20.0459C5.83455 20.2573 5.54791 20.376 5.24902 20.376C4.95014 20.376 4.66349 20.2573 4.45215 20.0459C4.2408 19.8346 4.12207 19.5479 4.12207 19.2491C4.12207 18.9502 4.2408 18.6635 4.45215 18.4522L10.4062 12.5L4.45402 6.54593C4.24268 6.33459 4.12395 6.04795 4.12395 5.74906C4.12395 5.45017 4.24268 5.16353 4.45402 4.95218C4.66537 4.74084 4.95201 4.62211 5.2509 4.62211C5.54978 4.62211 5.83643 4.74084 6.04777 4.95218L12 10.9062L17.954 4.95125C18.1654 4.7399 18.452 4.62117 18.7509 4.62117C19.0498 4.62117 19.3364 4.7399 19.5478 4.95125C19.7591 5.16259 19.8778 5.44924 19.8778 5.74812C19.8778 6.04701 19.7591 6.33365 19.5478 6.545L13.5937 12.5L19.5459 18.4541Z" fill="black" fill-opacity="0.4"/>
  </svg>
  `
  );

  // renderOrderInBusket(orderList);

  busketClose.addEventListener("click", () => {
    busketBlock.classList.remove("active");
  });

  busketBlockFooter.append(busketBlockFullPrice, orderBtn);
  busketBlockMain.append(busketBlockList);
  busketBlock.append(
    busketBlockHeader,
    busketBlockMain,
    busketBlockFooter,
    busketClose
  );

  busketBtn.append(busketQuantitySpan);
  busket.append(busketBtn, busketBlock, orderModal.modalContainer);
  renderOrderDefault(orderList);
  function renderOrderDefault(orderList) {
    busketBlockList.innerHTML = "";
    let orderSize = 0;
    let orderPrice = 0;

    orderList.forEach((product) => {
      const li = getElement("li", "busket__block--item");
      li.dataset.index = product.index;
      const liImage = getElement("img", "busket__item--img");
      liImage.src = product.image;
      const liInfo = getElement("div", "busket__item--info");
      const liTitle = getElement("div", "busket__item--title");
      liTitle.innerText = `${product.title} `;

      if (product.targetType) {
        liTitle.innerText += `(${product.targetType})`;
      }

      const liPrice = getElement("div", "busket__item--price");
      liPrice.innerText = `${product.price} руб`;
      const liQuantity = getElement("div", "busket__item--quantity");
      const liQuantityPlus = getElement(
        "button",
        "busket__item--plus",
        "btn-reset"
      );
      liQuantityPlus.innerText = "+";
      const liQuantityText = getElement("div");
      const liQuantityMinus = getElement(
        "button",
        "busket__item--minus",
        "btn-reset"
      );
      liQuantityMinus.innerText = "-";
      liQuantityText.innerText = `${product.quantity}`;

      const liRemove = getElement(
        "button",
        "busket__item--remove",
        "btn-reset"
      );
      liRemove.innerText = "Удалить";

      liQuantityPlus.addEventListener("click", () => {
        product.quantity++;
        renderOrderInBusket(orderList);
        localStorage.setItem("orderList", JSON.stringify(orderList));
      });

      liQuantityMinus.addEventListener("click", () => {
        if (+product.quantity - 1 === 0) {
          removeProductFromBusket(product.index);
        } else {
          product.quantity--;
          renderOrderInBusket(orderList);
        }
        localStorage.setItem("orderList", JSON.stringify(orderList));
      });

      liRemove.addEventListener("click", () =>
        removeProductFromBusket(product.index)
      );
      liQuantity.append(liQuantityMinus, liQuantityText, liQuantityPlus);
      liInfo.append(liTitle, liPrice, liQuantity, liRemove);
      li.append(liImage, liInfo);

      busketBlockList.append(li);
      orderSize += product.quantity;
      orderPrice += product.quantity * product.price;
    });

    busketBlockFullPrice.innerText = `Итого: ${orderPrice} руб`;
    busketQuantitySpan.innerText = orderSize;
  }

  return { busket };
}
